import { NuxtAxiosInstance } from "@nuxtjs/axios"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"

export class CreditCodeService {
  constructor(private axios: NuxtAxiosInstance, private config: NuxtRuntimeConfig) {}
  async getCreditCodeValue(page: Page, route): Promise<any | null> {
    if (process.server) {
      return null
    }

    let query = route.query || []
    let creditCode: string | null = query["credit-code"] || query["credit_code"]
    if (creditCode && typeof creditCode === "string") {
      const applyResponse = await this.applyCreditCode(creditCode)
      if (applyResponse.status === 200) {
        return applyResponse.data
      }
    }

    // Trim the / left and right
    let trimmedPath = route.path.replace(/^\/+|\/+$/g, "")
    let pathSegments = trimmedPath.split("/")
    creditCode = null

    if (pathSegments.length === 1) {
      if (pathSegments[0] === "discount" || pathSegments[0] === "angus") {
        creditCode = "RemovalsDiscount65642430"
      }
    } else if (pathSegments.length === 2 && pathSegments[0] === "partners") {
      let partnerName = pathSegments[1]
      if (partnerName === "uk-storage") {
        creditCode = "REM8d5069727739966be"
      }
    }

    if (creditCode !== null) {
      const applyResponse = await this.applyCreditCode(creditCode)
      if (applyResponse.status === 200) {
        return applyResponse.data
      }
    }

    if (page.locationForm && page.locationForm.categoryId) {
      const getResponse = await this.getCategoryBasedCredit(page.locationForm.categoryId)
      if (getResponse.status === 200) {
        return getResponse.data
      }
    }

    return null
  }

  applyCreditCode(creditCode) {
    // URL should be on top level
    const creditCodeUrl = this.config.v4Host + "/ng/api/v3/actions/apply-credit-code"
    return this.axios.post(
      creditCodeUrl,
      {
        credit_code: creditCode
      },
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        withCredentials: true
      }
    )
  }

  getCategoryBasedCredit(categoryId) {
    // URL should be on top level
    const creditCodeUrl = this.config.v4Host + "/ng/api/v3/actions/check-credit-code/" + categoryId
    return this.axios.get(creditCodeUrl, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
      withCredentials: true
    })
  }
}
